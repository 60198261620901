import React from 'react';
const logo = require('./assets/logo.png');

const App: React.FC = () => {
  return (
    <div className='main'>
      <img src={logo} alt="Cactuscode" width={500} />
    </div>
  )
}

export default App;